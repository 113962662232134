<template>
  <Dropdown
    id="tipoIntegracao"
    v-model="localValue"
    :options="tiposIntegracao"
    :placeholder="placeholder"
    v-bind="$attrs"
    optionLabel="label"
    optionValue="value"
  ></Dropdown>
</template>

<script>
import TipoIntegracao from '@/enums/tipo-integracao';

export default {
  props: {
    modelValue: String,
    placeholder: {
      type: String,
      default: 'Escolha um tipo de integração',
    },
  },
  data() {
    return {
      tiposIntegracao: TipoIntegracao.values,
    };
  },
  computed: {
    localValue: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit('update:modelValue', val);
      }
    }
  }
};
</script>
