<template>
  <div class="grid">
    <div class="col-12">
      <div class="font-medium text-3xl text-900 mb-3">
        <span class="text-primary text-5xl">/</span> {{ title }}
      </div>
      <div class="text-500 mb-5">Preencha os campos abaixo</div>

      <div class="card p-fluid w-full" style="max-width: 550px">
        <h5 class="mb-4">Novo cliente</h5>

        <Toast/>

        <div v-if="loading" class="text-center">
          <CircularLoading />
        </div>
        <div v-else>
          <div class="">
            <div class="field w-6">
              <label for="cnpj">CNPJ</label>
              <InputMask
                id="cnpj"
                v-model.trim="form.cnpj"
                @blur="v$.form.cnpj.$touch"
                mask="99.999.999/9999-99"
                required="true"
                autofocus
                :class="{ 'p-invalid': v$.form.cnpj.$errors.length }"
              />
              <InputFieldErrors :errors="v$.form.cnpj.$errors"/>
            </div>

            <div class="field">
              <label for="razaoSocial">Razão social</label>
              <InputText
                id="razaoSocial"
                v-model.trim="form.razaoSocial"
                @blur="v$.form.razaoSocial.$touch"
                required="true"
                :class="{ 'p-invalid': v$.form.razaoSocial.$errors.length }"
              />
              <InputFieldErrors :errors="v$.form.razaoSocial.$errors"/>
            </div>

            <div class="field">
              <label for="nomeFantasia">Nome fantasia</label>
              <InputText
                id="nomeFantasia"
                @blur="v$.form.nomeFantasia.$touch"
                v-model.trim="form.nomeFantasia"
                required="true"
                :class="{ 'p-invalid': v$.form.nomeFantasia.$errors.length }"
              />
              <InputFieldErrors :errors="v$.form.nomeFantasia.$errors"/>
            </div>

            <div class="field w-6">
              <label for="cep">CEP</label>
              <InputMask id="cep" v-model.trim="form.cep" mask="99999-999"/>
            </div>

            <div class="field">
              <label for="endereco">Endereço</label>
              <InputText id="endereco" v-model.trim="form.endereco"/>
            </div>

            <div class="field">
              <label for="bairro">Bairro</label>
              <InputText id="bairro" v-model.trim="form.bairro"/>
            </div>

            <div class="field">
              <label for="cidade">Cidade</label>
              <InputText id="cidade" v-model.trim="form.cidade"/>
            </div>

            <div class="field w-6">
              <label for="estado">Estado</label>
              <Dropdown
                id="estado"
                v-model="form.estado"
                :options="estados"
                optionLabel="label"
                optionValue="value"
                placeholder="Escolha um estado"
              ></Dropdown>
            </div>
          </div>

          <Divider class="mt-5"/>
          <div>
            <h5 class="mb-4">Dados de integração</h5>
            <div class="field w-6">
              <TipoIntegracaoSelect v-model="form.tipoIntegracao"
                                    @blur="v$.form.tipoIntegracao.$touch"
                                    required="true"
                                    :class="{ 'p-invalid': v$.form.tipoIntegracao.$errors.length }"/>
              <InputFieldErrors :errors="v$.form.tipoIntegracao.$errors"/>
            </div>
            <div class="field">
              <label for="url">URL</label>
              <InputText
                id="url"
                v-model.trim="form.url"
              />
            </div>
            <div class="field">
              <label for="user">Usuário</label>
              <InputText
                id="user"
                v-model.trim="form.user"
              />
            </div>
            <div class="field">
              <label for="password">Senha</label>
              <InputText
                id="password"
                v-model.trim="form.password"
              />
            </div>
            <div class="field">
              <label for="agentId">Agent ID</label>
              <InputText
                id="agentId"
                v-model.trim="form.agentId"
              />
            </div>
          </div>
          <div class="flex mt-5">
            <FileUpload choose-label="Logo"
                        mode="basic"
                        name="file[]"
                        accept="image/*"
                        custom-upload
                        auto
                        :disabled="fileLoading"
                        @uploader="fileUploader"/>
            <Button
              v-if="form.logo"
              label="Remover logo"
              icon="pi pi-times"
              class="p-button-text p-button-sm w-3 ml-2"
              @click="removeLogo"
            />
          </div>
          <div class="flex mt-5">
            <Button
              label="Salvar"
              icon="pi pi-check"
              class="p-button-primary w-6"
              @click="save"
            />
            <Button
              label="Voltar"
              icon="pi pi-times"
              class="p-button-text w-3 ml-auto"
              @click="$router.push('/clientes')"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CrudService from '@/services/crud'
import { useVuelidate } from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import TipoIntegracaoSelect from "@/components/TipoIntegracaoSelect";
import InputFieldErrors from "@/components/InputFieldErrors";
import { getClient } from '@/services/http';

export default {
  components: {InputFieldErrors, TipoIntegracaoSelect},
  setup () {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      fileLoading: false,
      form: {
        cnpj: null,
        razaoSocial: null,
        nomeFantasia: null,
        cep: null,
        endereco: null,
        bairro: null,
        cidade: null,
        estado: null,
        tipoIntegracao: null,
        url: null,
        logo: null,
        user: null,
        password: null,
        agentId: null,
      },
      estados: [
        {value: 'AC', label: 'Acre'},
        {value: 'AL', label: 'Alagoas'},
        {value: 'AP', label: 'Amapá'},
        {value: 'AM', label: 'Amazonas'},
        {value: 'BA', label: 'Bahia'},
        {value: 'CE', label: 'Ceará'},
        {value: 'ES', label: 'Espírito Santo'},
        {value: 'GO', label: 'Goiás'},
        {value: 'MA', label: 'Maranhão'},
        {value: 'MT', label: 'Mato Grosso'},
        {value: 'MS', label: 'Mato Grosso do Sul'},
        {value: 'MG', label: 'Minas Gerais'},
        {value: 'PA', label: 'Pará'},
        {value: 'PB', label: 'Paraíba'},
        {value: 'PR', label: 'Paraná'},
        {value: 'PE', label: 'Pernambuco'},
        {value: 'PI', label: 'Paiuí'},
        {value: 'RJ', label: 'Rio de Janeiro'},
        {value: 'RN', label: 'Rio Grande do Norte'},
        {value: 'RS', label: 'Rio Grande do Sul'},
        {value: 'RO', label: 'Rondônia'},
        {value: 'RR', label: 'Roraima'},
        {value: 'SC', label: 'Santa Catarina'},
        {value: 'SP', label: 'São Paulo'},
        {value: 'SE', label: 'Sergipe'},
        {value: 'TO', label: 'Tocantins'},
        {value: 'DF', label: 'Distrito Federal'}
      ],
      submitted: false,
      loading: false
    }
  },
  async mounted() {
    this.$service = new CrudService('/admin/clientes')
    if (this.$route.params.id) {
      this.loading = true;
      const {data} = await this.$service.findById(this.$route.params.id)
      this.form = data
      this.loading = false;
    }
  },
  computed: {
    title() {
      return this.$route.params.id ? 'Editar cliente' : 'Adiconar cliente'
    }
  },
  methods: {
    async save() {
      if (this.isInvalid()) {
        return;
      }
      this.submitted = true
      try {
        await this.$service.save(this.getNormalizeFields())
        this.$toast.add({
          severity: 'success',
          summary: 'Cliente gravado com sucesso!',
          life: 3000
        })
        this.$router.replace('list')
      } catch (err) {
        this.$toast.add({
          severity: 'error',
          summary: 'Problemas ao armazenar o cliente!',
          detail: err?.response?.data?.message,
          life: 3000
        })
        console.log(err)
      }
    },
    isInvalid() {
      this.v$.$touch();
      return this.v$.$invalid;
    },
    getNormalizeFields() {
      let form = this.form
      form.cnpj = this.onlyNumbers(form.cnpj)
      form.cep = this.onlyNumbers(form.cep)
      return form
    },
    onlyNumbers(value) {
      let numberPattern = /\d+/g
      let numbers = value.match(numberPattern)

      if (numbers === null) {
        return 0
      }

      //return parseInt(numbers.join([]))
      return numbers.join([])
    },
    async fileUploader(event) {
      this.fileLoading = true;
      const [ file ] = event.files;
      const formData = new FormData();
      formData.append('file', file);
      const response = await getClient().post('admin/clientes/logo', formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        }
      })
      this.fileLoading = false;
      this.form.logo = response.data;
    },
    removeLogo() {
      this.form.logo = null;
    }
  },
  validations() {
    return {
      form: {
        cnpj: {
          required: helpers.withMessage('CNPJ é obrigatório.', required),
        },
        razaoSocial: {
          required: helpers.withMessage('Razão social é obrigatório.', required),
        },
        nomeFantasia: {
          required: helpers.withMessage('Nome fantasia é obrigatório.', required),
        },
        tipoIntegracao: {
          required: helpers.withMessage('Tipo de integração é obrigatório.', required),
        },
      },
    };
  },

}
</script>

<style scoped lang="scss">
</style>
