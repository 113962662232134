<template>
  <small class="p-error">
    <span :key="error.$message" v-for="error in errors">
      {{ error.$message }}
    </span>
  </small>
</template>

<script>
export default {
  props: {
    errors: Array,
  },
};
</script>
